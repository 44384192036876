<script setup>
import MainNavigationItem from "@/components/MainNavigationItem.vue"

const navigation = [
  { name: "Pricing", href: "/pricing", pathRoot: "pricing" },
  { name: "Blog", href: "/blog", pathRoot: "blog" },
  { name: "About", href: "/about", pathRoot: "about" },
  { name: "Contact", href: "/contact", pathRoot: "contact" },
]
</script>
<template>
  <header
    class="relative sm:fixed w-full py-4 bg-white bg-opacity-95 backdrop-blur border-b border-indigo-50 z-10 transition-opacity duration-300"
  >
    <div
      class="max-w-7xl mx-auto flex flex-col sm:flex-row justify-between items-center px-4 gap-2"
    >
      <!-- Logo Section -->
      <div
        class="flex-shrink-0 flex justify-center sm:justify-start sm:mb-0 w-auto"
      >
        <NuxtLink to="/">
          <img
            src="/images/streamfold-logo-light.png"
            alt="Streamfold"
            class="max-h-8 w-auto object-contain"
          />
        </NuxtLink>
      </div>
      <!-- Navigation Section -->
      <div
        class="flex flex-col sm:flex-row sm:justify-center items-center gap-2 flex-grow w-full"
      >
        <MainNavigationItem
          v-for="item in navigation"
          :key="item.name"
          :to="item.href"
          :path-root="item.pathRoot"
          :external="item.external"
        >
          {{ item.name }}
        </MainNavigationItem>
      </div>
      <!-- Waitlist and Sign-in Buttons Section -->
      <div
        class="flex flex-col sm:flex-row flex-grow justify-end gap-2 w-full sm:w-auto"
      >
        <NuxtLink
          to="https://docs.streamfold.com/"
          class="w-full sm:w-auto"
          target="_blank"
        >
          <button
            class="h-8 bg-white text-slate-600 font-semibold text-xs px-4 rounded border whitespace-nowrap border-slate-200 hover:bg-white hover:text-indigo-500 hover:border-indigo-100 cursor-pointer w-full"
          >
            Docs
          </button>
        </NuxtLink>
        <NuxtLink to="https://app.streamfold.com/" class="w-full sm:w-auto">
          <button
            class="h-8 bg-white text-slate-600 font-semibold text-xs px-4 rounded border whitespace-nowrap border-slate-200 hover:bg-white hover:text-indigo-500 hover:border-indigo-100 cursor-pointer w-full"
          >
            Sign in
          </button>
        </NuxtLink>
        <NuxtLink to="/waitlist/" class="w-full">
          <button
            class="h-8 bg-indigo-500 text-white font-semibold text-xs px-4 rounded border-transparent whitespace-nowrap hover:bg-indigo-600 hover:text-white hover:outline-none cursor-pointer w-full"
          >
            Request Early Access
          </button>
        </NuxtLink>
      </div>
    </div>
  </header>
</template>
