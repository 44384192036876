<script setup lang="ts"></script>

<template>
  <Body class="bg-white" />

  <div class="flex flex-col">
    <SiteHeader />

    <!-- Main Content-->
    <main class="flex-grow sm:mt-16">
      <slot />
    </main>

    <SiteFooter />
  </div>
</template>
